<script lang="ts">
	import { addDynamicClasses } from '$utils/frontend'

	type Props = {
		borderRadius?: number
		scale?: number
		opaque?: boolean
		relativeParent?: number
	}
	let { borderRadius = 0.5, scale = 1, opaque = true, relativeParent = 1 }: Props = $props()
</script>

<div
	class={addDynamicClasses('backdrop', [`parent-relative-${relativeParent}`])}
	class:opaque
	style:border-radius={`${borderRadius}rem`}
>
	<div class="spinner-wrapper" style:transform={`translate(-50%, -50%) scale(${scale})`}>
		<span class="spinner"></span>
	</div>
</div>

<style lang="scss">
	.backdrop {
		position: absolute;
		inset: 0;
		z-index: 10;

		&.opaque {
			background-color: rgba($background-dark, 0.5);
		}

		.spinner-wrapper {
			position: absolute;
			// top: 57%;
			top: 50%;
			left: 50%;
			.spinner {
				width: 48px;
				height: 48px;
				border-radius: 50%;
				display: inline-block;
				position: relative;
				border: 3px solid;
				border-color: $white $white transparent transparent;
				box-sizing: border-box;
				animation: rotation 1s linear infinite;

				&::after,
				&::before {
					content: '';
					box-sizing: border-box;
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					margin: auto;
					border: 3px solid;
					border-color: transparent transparent $warn $warn;
					width: 40px;
					height: 40px;
					border-radius: 50%;
					box-sizing: border-box;
					animation: rotationBack 0.5s linear infinite;
					transform-origin: center center;
				}

				&::before {
					width: 32px;
					height: 32px;
					border-color: #fff #fff transparent transparent;
					animation: rotation 1.5s linear infinite;
				}
			}

			@keyframes rotation {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}
			@keyframes rotationBack {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(-360deg);
				}
			}
		}
	}
</style>
