<script lang="ts">
	import type { FormStatus } from '$types/ui'
	import { onMount } from 'svelte'
	import Spinner from './Spinner.svelte'
	import { addDynamicClasses } from '$utils/frontend'

	type Props = {
		formStatus: FormStatus
		floatingMsgs?: boolean
		floatingMsgTime?: number
		pendingFlMsMinW?: number
		errorFlMsMinW?: number
		successFlMsMinW?: number
		relativeParent?: number
		spinner?: boolean
		spinnerBorderRadius?: number
		spinnerScale?: number
		spinnerOpaque?: boolean
	}
	let {
		formStatus,
		floatingMsgs = true,
		floatingMsgTime = 2,
		pendingFlMsMinW = 8,
		errorFlMsMinW = 8,
		successFlMsMinW = 8,
		relativeParent = 1,
		spinner = true,
		spinnerBorderRadius = 0.5,
		spinnerScale = 1,
		spinnerOpaque = true,
	}: Props = $props()

	let pendSecs: number = $state(0)
	let pendEnding = $derived('.'.repeat(pendSecs % 4))
	let pendUi = $derived(formStatus.messages.pending + pendEnding)
	let relativeParentClass = $derived(floatingMsgs ? `parent-relative-${relativeParent}` : '')
	let pendInterval: ReturnType<typeof setInterval> | null = null
	$effect(() => {
		if (formStatus.state === 'pending' && pendInterval === null && !spinner)
			pendInterval = setInterval(() => pendSecs++, 300)
		if (formStatus.state !== 'pending' && pendInterval !== null) {
			clearInterval(pendInterval)
			pendInterval = null
		}
	})
	onMount(() => () => pendInterval && clearInterval(pendInterval))
</script>

{#if formStatus.state === 'pending'}
	{#if spinner}
		<Spinner
			{relativeParent}
			borderRadius={spinnerBorderRadius}
			scale={spinnerScale}
			opaque={spinnerOpaque}
		/>
	{:else}
		<p
			class={addDynamicClasses('result pending', [relativeParentClass])}
			class:floating-msgs={floatingMsgs}
			style:min-width={floatingMsgs ? `${pendingFlMsMinW}rem` : undefined}
			style:animation-duration={`${floatingMsgTime}s`}
		>
			{pendUi}
		</p>
	{/if}
{:else if formStatus.state === 'failure' && formStatus.issue}
	<p
		class={addDynamicClasses('result error', [relativeParentClass])}
		class:floating-msgs={floatingMsgs}
		style:min-width={floatingMsgs ? `${errorFlMsMinW}rem` : undefined}
		style:animation-duration={`${floatingMsgTime * 3}s`}
	>
		{formStatus.issue.message}
	</p>
{:else if formStatus.state === 'success'}
	<p
		class={addDynamicClasses('result valid', [relativeParentClass])}
		class:floating-msgs={floatingMsgs}
		style:min-width={floatingMsgs ? `${successFlMsMinW}rem` : undefined}
		style:animation-duration={`${floatingMsgTime}s`}
	>
		{formStatus.messages.success}
	</p>
{/if}

<style lang="scss">
	.result {
		color: $neutral;
		text-align: center;
		font-size: 1rem;

		&.error {
			color: $error;
		}

		&.valid {
			color: $valid;
		}

		&.floating-msgs {
			position: absolute;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
			padding: 0.5rem;
			box-shadow: 0px 0px 1rem 0.25rem rgba($black, 0.3);
			background-color: $popup;
			border-radius: 0.5rem;
			min-width: 8rem;
			z-index: 10;

			&:not(.error) {
				animation-name: dropout;
				animation-timing-function: ease-in-out;
				animation-iteration-count: 1;
				animation-fill-mode: forwards;
			}
			&.error {
				filter: saturate(1.5);
				animation-name: fade;
				animation-timing-function: ease-out;
				animation-iteration-count: 1;
				animation-fill-mode: forwards;
			}
		}
		@keyframes dropout {
			from {
				transform: translate(-50%, 0%);
			}
			to {
				transform: translate(-50%, 100%);
				opacity: 0;
				display: none;
			}
		}
		@keyframes fade {
			0% {
				opacity: 1;
			}
			80% {
				opacity: 1;
			}
			100% {
				opacity: 0;
				display: none;
			}
		}
	}
</style>
