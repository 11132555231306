import type {
	FormState,
	FormStateMessages,
	FormStatus,
	FormValues,
	InputValue,
	InputValues,
} from '$types/ui'
import type { ZodIssue } from 'zod'

export const createFormStatus = ({
	unsent = 'Form is not yet sent',
	pending = 'Please wait',
	success = 'Success!',
}: Partial<FormStateMessages>): FormStatus => {
	let state = $state('unsent') as FormState
	let issue: ZodIssue | null = $state(null)
	const disabled = $derived(state === 'pending')

	return {
		get state() {
			return state
		},
		set state(_state: FormState) {
			state = _state
		},
		get issue() {
			return issue
		},
		set issue(_issue: ZodIssue | null) {
			issue = _issue
		},
		get disabled() {
			return disabled
		},
		messages: { unsent, pending, success },
	}
}

export const createForm = (
	initialValues: InputValues,
	messages: Partial<FormStateMessages> = {},
) => {
	const formValues: FormValues = {}
	for (const [key, initialValue] of Object.entries(initialValues)) {
		let value = $state(initialValue)
		let touched = $state(false)
		let valid = $state(false)

		formValues[key] = {
			get value() {
				return value
			},
			get touched() {
				return touched
			},
			get valid() {
				return valid
			},
			set value(_value: InputValue) {
				value = _value
			},
			set touched(_touched: boolean) {
				touched = _touched
			},
			set valid(_valid: boolean) {
				valid = _valid
			},
		}
	}

	const status: FormStatus = createFormStatus(messages)

	const valid: boolean = $derived.by(() => {
		for (const [, value] of Object.entries(formValues)) {
			if (!value.valid) return false
		}
		return true
	})

	const touchAll = () => {
		for (const [, value] of Object.entries(formValues)) {
			value.touched = true
		}
	}

	const resetFormValues = () => {
		for (const [key, initialValue] of Object.entries(initialValues)) {
			formValues[key]!.value = initialValue
			formValues[key]!.touched = false
			formValues[key]!.valid = false
		}
	}

	const resetForm = () => {
		resetFormValues()
		status.state = 'unsent'
		status.issue = null
	}

	return {
		values: formValues,
		get status() {
			return status
		},
		get valid() {
			return valid
		},
		touchAll,
		resetValues: resetFormValues,
		resetForm,
	}
}
