import { browser } from '$app/environment'
import type { WithPrefix, HasProperty } from '$types/common'
import Decimal from 'decimal.js'
import { ZodError, type ZodIssue } from 'zod'

export const copyRecord = <T extends object>(record: Record<string, T>): Record<string, T> => {
	const result: Record<string, T> = {}
	for (const [key, value] of Object.entries(record)) {
		result[key] = { ...value }
	}
	return result
}

export const deletePrefixReturnPlain = <
	T extends object,
	V extends string,
	U extends WithPrefix<T, V>,
>(
	input: U,
	prefix: V,
): HasProperty => {
	const result: HasProperty = {}
	for (const prop in input) {
		if (!Object.hasOwn(input, prop) || !prop.startsWith(`${prefix}_`)) continue
		const newProp = prop.slice(prefix.length + 1)
		result[newProp] = input[prop]
	}
	return result
}

export const areAllPropsNull = (obj: HasProperty) => {
	for (const prop in obj) {
		if (!Object.hasOwn(obj, prop)) continue
		if (obj[prop] !== null) return false
	}
	return true
}

export const removeDuplicateObj = <T>(arrObj: T[], key: keyof T): T[] =>
	arrObj.filter((value, index, self) => index === self.findIndex((x) => x[key] === value[key]))

export const compareISOString = (a: string, b: string): number => {
	const aDate = new Date(a)
	const bDate = new Date(b)
	const dif = aDate.valueOf() - bDate.valueOf()
	if (dif > 0) return 1
	if (dif < 0) return -1
	if (dif === 0) return 0
	return NaN
}

export const compareISOStringLatest = (a: string, b: string): number =>
	-1 * compareISOString(a, b)

export const compareISOStringModifiedOnLatest = <T extends { modifiedOn: string }>(
	a: T,
	b: T,
) => compareISOStringLatest(a.modifiedOn, b.modifiedOn)

export const compareDecimal = (a: string, b: string): number => {
	const aDec = new Decimal(a)
	const bDec = new Decimal(b)
	return aDec.comparedTo(bDec)
}

export const compareDecOrdinal = <T extends { ordinal: string }>(a: T, b: T) =>
	compareDecimal(a.ordinal, b.ordinal)

export const nextOrdinal = (ord: string): string => Decimal.add(ord, '1').toString()

export const avgDec = (a: string, b: string): string =>
	Decimal.add(a, b).dividedBy(2).toString()

export const actionToUrl = (action: string): string => `?/${action}`

type ZodIssueProps = {
	message: string
	path?: string[]
}
export const toZodIssue = ({ message, path = [] }: ZodIssueProps): ZodIssue => ({
	code: 'custom',
	message,
	path,
})

export const toZodError = ({ message, path = [] }: ZodIssueProps): ZodError =>
	new ZodError([toZodIssue({ message, path })])

export const issueToZodError = (issue: ZodIssue): ZodError => new ZodError([issue])

export const toActionRes = ({ message, path = [] }: ZodIssueProps) => ({
	issue: toZodIssue({ message, path }),
})

export const issueToActionRes = (issue: ZodIssue) => ({
	issue,
})

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))

export const isEmpty = (obj: object) => {
	for (const prop in obj) {
		if (Object.hasOwn(obj, prop)) {
			return false
		}
	}

	return true
}

export const setDifferenceObj = <U>(as: U[], bs: U[], key: keyof U): U[] =>
	as.filter((a) => bs.every((b) => a[key] !== b[key]))

export const setDifference = <T>(as: T[], bs: T[]): T[] =>
	as.filter((a) => bs.every((b) => a !== b))

export const isMobile = browser
	? /iPhone|iPad|iPod|Android|Mobi/i.test(navigator.userAgent)
	: false

export const actionToGtag = (action: string) => action.replaceAll('-', '_')
