export const getLastUiId = <T extends { id: string }>(objs: T[]): string =>
	objs.reduce((acc: string, cur: T): string => {
		const accNumber = parseInt(acc)
		const curNumber = parseInt(cur.id)
		if (isNaN(curNumber)) return acc
		return Math.min(accNumber, curNumber).toString()
	}, '0')

export const nextUiId = <T extends { id: string }>(objs: T[]): string =>
	(parseInt(getLastUiId(objs)) - 1).toString()

export const addDynamicClasses = (iClasses: string, dynClasses: string[]): string =>
	dynClasses.reduce((acc, cur) => `${acc}${cur ? ` ${cur}` : ''}`, iClasses)
